import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Select from "react-select";
import Alert from "sweetalert";
import * as yup from "yup";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Header from "../../components/Header";
import MainServices from "../../components/MainServices";
import Title from "../../components/Title";
import api from "../../services/api";

import {
  Aside,
  Container,
  Content,
  ContentBody,
  ContentMain,
  Main,
  ContentBox,
  AvisoBox,
  AvisoTitle,
  AvisoContent,
  Form,
  FieldContainer,
  Label,
  InputText,
  TextArea,
  Collumn,
  CollumnContainer,
  HorarioContainer,
  HorarionButton,
  Enviar,
  EnviarContainer,
} from "./styles";
import { format, formatISO, getDay, parseISO } from "date-fns";

const destino = [
  { value: "Secretaria e Cadastro", label: "Secretaria e Cadastro" },
  { value: "Financeiro", label: "Financeiro" },
  { value: "Jurídico", label: "Jurídico" },
  { value: "Fiscalização", label: "Fiscalização" },
];

const defaultHorario = [
  { horario: "08:00", disabled: false },
  { horario: "09:00", disabled: false },
  { horario: "10:00", disabled: false },
  { horario: "11:00", disabled: false },
  { horario: "13:00", disabled: false },
  { horario: "14:00", disabled: false },
  { horario: "15:00", disabled: false },
  { horario: "16:00", disabled: false },
];

const formSchema = yup.object().shape({
  name: yup.string().required("O nome precisa ser informado."),
  email: yup.string().email("Deve ser um email valido").required("O email precisa ser informado").typeError("Deve ser um email valido"),
  phone: yup.string().required("O telefone precisa ser informado"),
  horario: yup.string().required("O horario precisa ser selecionado"),
  setor_destino: yup.string().required("O destino precisa ser selecionado."),
  date: yup.date().required("A data precisa ser informada.").typeError("A data deve ser informada"),
  solicitacao: yup.string().required("A solicitação precisa ser informada"),
});

function Agendamento() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [horario, setHorario] = useState("");
  const [setor_destino, setSetorDestino] = useState("");
  const [cro, setCRO] = useState("");
  const [sede, setSede] = useState("");
  const [date, setDate] = useState(null);
  const [solicitacao, setSolicitacao] = useState("");
  const [sedeOptions, setSedeOptions] = useState([]);
  const [horarios, setHorarios] = useState(defaultHorario);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    async function LoadData() {
      try {
        await api.get("/sede").then(({ data }) => {
          let sedes = [];
          data.forEach(item => {
            sedes.push({ value: item.id, label: item.name });
          });
          setSedeOptions(sedes);
        });
      } catch (error) {
        Alert("Erro", `${error.errors}`, "error");
      }
    }
    LoadData();
  }, []);

  const handleBloqueio = value => {
    try {
      if (new Date(value).getDate()) {
        const selected = formatISO(new Date(value), { representation: "date" });

        const bloqueioRoo = [
          formatISO(parseISO("2022-12-14"), { representation: "date" }),
          formatISO(parseISO("2022-12-15"), { representation: "date" }),
          formatISO(parseISO("2022-12-16"), { representation: "date" }),
          formatISO(parseISO("2022-12-17"), { representation: "date" }),
          formatISO(parseISO("2022-12-18"), { representation: "date" }),
          formatISO(parseISO("2022-12-19"), { representation: "date" }),
          formatISO(parseISO("2022-12-20"), { representation: "date" }),
          formatISO(parseISO("2022-12-21"), { representation: "date" }),
          formatISO(parseISO("2022-12-22"), { representation: "date" }),
          formatISO(parseISO("2022-12-23"), { representation: "date" }),
          formatISO(parseISO("2022-12-24"), { representation: "date" }),
          formatISO(parseISO("2022-12-25"), { representation: "date" }),
          formatISO(parseISO("2022-12-26"), { representation: "date" }),
          formatISO(parseISO("2022-12-27"), { representation: "date" }),
          formatISO(parseISO("2022-12-28"), { representation: "date" }),
          formatISO(parseISO("2022-12-29"), { representation: "date" }),
          formatISO(parseISO("2022-12-30"), { representation: "date" }),
          formatISO(parseISO("2022-12-31"), { representation: "date" }),
          formatISO(parseISO("2023-01-01"), { representation: "date" }),
          formatISO(parseISO("2023-01-02"), { representation: "date" }),
          formatISO(parseISO("2023-01-03"), { representation: "date" }),
          formatISO(parseISO("2023-06-17"), { representation: "date" }),
          formatISO(parseISO("2023-06-18"), { representation: "date" }),
          formatISO(parseISO("2023-06-19"), { representation: "date" }),
          formatISO(parseISO("2023-06-20"), { representation: "date" }),
          formatISO(parseISO("2023-06-21"), { representation: "date" }),
          formatISO(parseISO("2024-12-02"), { representation: "date" }),
          formatISO(parseISO("2024-12-03"), { representation: "date" }),
          formatISO(parseISO("2024-12-04"), { representation: "date" }),
          formatISO(parseISO("2024-12-05"), { representation: "date" }),
          formatISO(parseISO("2024-12-06"), { representation: "date" }),

          formatISO(parseISO("2024-12-23"), { representation: "date" }),
          formatISO(parseISO("2024-12-24"), { representation: "date" }),
          formatISO(parseISO("2024-12-25"), { representation: "date" }),
          formatISO(parseISO("2024-12-26"), { representation: "date" }),
          formatISO(parseISO("2024-12-27"), { representation: "date" }),
          formatISO(parseISO("2024-12-28"), { representation: "date" }),
          formatISO(parseISO("2024-12-29"), { representation: "date" }),
          formatISO(parseISO("2024-12-30"), { representation: "date" }),
          formatISO(parseISO("2024-12-31"), { representation: "date" }),
          formatISO(parseISO("2025-01-01"), { representation: "date" }),
          formatISO(parseISO("2025-01-02"), { representation: "date" }),
          formatISO(parseISO("2025-01-03"), { representation: "date" }),
          formatISO(parseISO("2025-01-04"), { representation: "date" }),
          formatISO(parseISO("2025-01-05"), { representation: "date" }),
          formatISO(parseISO("2025-01-06"), { representation: "date" }),
        ];

        const bloqueioBarra = [
          formatISO(parseISO("2023-01-30"), { representation: "date" }),
          formatISO(parseISO("2023-01-31"), { representation: "date" }),
          formatISO(parseISO("2024-12-02"), { representation: "date" }),
          formatISO(parseISO("2024-12-03"), { representation: "date" }),
          formatISO(parseISO("2024-12-04"), { representation: "date" }),
          formatISO(parseISO("2024-12-05"), { representation: "date" }),
          formatISO(parseISO("2024-12-06"), { representation: "date" }),

          formatISO(parseISO("2024-12-23"), { representation: "date" }),
          formatISO(parseISO("2024-12-24"), { representation: "date" }),
          formatISO(parseISO("2024-12-25"), { representation: "date" }),
          formatISO(parseISO("2024-12-26"), { representation: "date" }),
          formatISO(parseISO("2024-12-27"), { representation: "date" }),
          formatISO(parseISO("2024-12-28"), { representation: "date" }),
          formatISO(parseISO("2024-12-29"), { representation: "date" }),
          formatISO(parseISO("2024-12-30"), { representation: "date" }),
          formatISO(parseISO("2024-12-31"), { representation: "date" }),
          formatISO(parseISO("2025-01-01"), { representation: "date" }),
          formatISO(parseISO("2025-01-02"), { representation: "date" }),
          formatISO(parseISO("2025-01-03"), { representation: "date" }),
          formatISO(parseISO("2025-01-04"), { representation: "date" }),
          formatISO(parseISO("2025-01-05"), { representation: "date" }),
          formatISO(parseISO("2025-01-06"), { representation: "date" }),
        ];

        const bloqueioTang = [
          formatISO(parseISO("2023-01-31"), { representation: "date" }),
          formatISO(parseISO("2024-12-02"), { representation: "date" }),
          formatISO(parseISO("2024-12-03"), { representation: "date" }),
          formatISO(parseISO("2024-12-04"), { representation: "date" }),
          formatISO(parseISO("2024-12-05"), { representation: "date" }),
          formatISO(parseISO("2024-12-06"), { representation: "date" }),

          formatISO(parseISO("2024-12-23"), { representation: "date" }),
          formatISO(parseISO("2024-12-24"), { representation: "date" }),
          formatISO(parseISO("2024-12-25"), { representation: "date" }),
          formatISO(parseISO("2024-12-26"), { representation: "date" }),
          formatISO(parseISO("2024-12-27"), { representation: "date" }),
          formatISO(parseISO("2024-12-28"), { representation: "date" }),
          formatISO(parseISO("2024-12-29"), { representation: "date" }),
          formatISO(parseISO("2024-12-30"), { representation: "date" }),
          formatISO(parseISO("2024-12-31"), { representation: "date" }),
          formatISO(parseISO("2025-01-01"), { representation: "date" }),
          formatISO(parseISO("2025-01-02"), { representation: "date" }),
          formatISO(parseISO("2025-01-03"), { representation: "date" }),
          formatISO(parseISO("2025-01-04"), { representation: "date" }),
          formatISO(parseISO("2025-01-05"), { representation: "date" }),
          formatISO(parseISO("2025-01-06"), { representation: "date" }),
        ];

        const bloqueioCuiaba = [
          formatISO(parseISO("2022-12-17"), { representation: "date" }),
          formatISO(parseISO("2022-12-18"), { representation: "date" }),
          formatISO(parseISO("2022-12-19"), { representation: "date" }),
          formatISO(parseISO("2022-12-20"), { representation: "date" }),
          formatISO(parseISO("2022-12-21"), { representation: "date" }),
          formatISO(parseISO("2022-12-22"), { representation: "date" }),
          formatISO(parseISO("2022-12-23"), { representation: "date" }),
          formatISO(parseISO("2022-12-24"), { representation: "date" }),
          formatISO(parseISO("2022-12-25"), { representation: "date" }),
          formatISO(parseISO("2022-12-26"), { representation: "date" }),
          formatISO(parseISO("2022-12-27"), { representation: "date" }),
          formatISO(parseISO("2022-12-28"), { representation: "date" }),
          formatISO(parseISO("2022-12-29"), { representation: "date" }),
          formatISO(parseISO("2022-12-30"), { representation: "date" }),
          formatISO(parseISO("2022-12-31"), { representation: "date" }),
          formatISO(parseISO("2023-01-01"), { representation: "date" }),
          formatISO(parseISO("2023-01-02"), { representation: "date" }),
          formatISO(parseISO("2023-01-03"), { representation: "date" }),
          formatISO(parseISO("2024-12-03"), { representation: "date" }),

          formatISO(parseISO("2024-12-23"), { representation: "date" }),
          formatISO(parseISO("2024-12-24"), { representation: "date" }),
          formatISO(parseISO("2024-12-25"), { representation: "date" }),
          formatISO(parseISO("2024-12-26"), { representation: "date" }),
          formatISO(parseISO("2024-12-27"), { representation: "date" }),
          formatISO(parseISO("2024-12-28"), { representation: "date" }),
          formatISO(parseISO("2024-12-29"), { representation: "date" }),
          formatISO(parseISO("2024-12-30"), { representation: "date" }),
          formatISO(parseISO("2024-12-31"), { representation: "date" }),
          formatISO(parseISO("2025-01-01"), { representation: "date" }),
          formatISO(parseISO("2025-01-02"), { representation: "date" }),
          formatISO(parseISO("2025-01-03"), { representation: "date" }),
          formatISO(parseISO("2025-01-04"), { representation: "date" }),
          formatISO(parseISO("2025-01-05"), { representation: "date" }),
          formatISO(parseISO("2025-01-06"), { representation: "date" }),
        ];

        const bloqueioSinop = [
          formatISO(parseISO("2022-12-14"), { representation: "date" }),
          formatISO(parseISO("2022-12-15"), { representation: "date" }),
          formatISO(parseISO("2022-12-16"), { representation: "date" }),
          formatISO(parseISO("2022-12-17"), { representation: "date" }),
          formatISO(parseISO("2022-12-18"), { representation: "date" }),
          formatISO(parseISO("2022-12-19"), { representation: "date" }),
          formatISO(parseISO("2022-12-20"), { representation: "date" }),
          formatISO(parseISO("2022-12-21"), { representation: "date" }),
          formatISO(parseISO("2022-12-22"), { representation: "date" }),
          formatISO(parseISO("2022-12-23"), { representation: "date" }),
          formatISO(parseISO("2022-12-24"), { representation: "date" }),
          formatISO(parseISO("2022-12-25"), { representation: "date" }),
          formatISO(parseISO("2022-12-26"), { representation: "date" }),
          formatISO(parseISO("2022-12-27"), { representation: "date" }),
          formatISO(parseISO("2022-12-28"), { representation: "date" }),
          formatISO(parseISO("2022-12-29"), { representation: "date" }),
          formatISO(parseISO("2022-12-30"), { representation: "date" }),
          formatISO(parseISO("2022-12-31"), { representation: "date" }),
          formatISO(parseISO("2023-01-01"), { representation: "date" }),
          formatISO(parseISO("2023-01-02"), { representation: "date" }),
          formatISO(parseISO("2024-12-02"), { representation: "date" }),
          formatISO(parseISO("2024-12-03"), { representation: "date" }),
          formatISO(parseISO("2024-12-04"), { representation: "date" }),
          formatISO(parseISO("2024-12-05"), { representation: "date" }),
          formatISO(parseISO("2024-12-06"), { representation: "date" }),

          formatISO(parseISO("2024-12-23"), { representation: "date" }),
          formatISO(parseISO("2024-12-24"), { representation: "date" }),
          formatISO(parseISO("2024-12-25"), { representation: "date" }),
          formatISO(parseISO("2024-12-26"), { representation: "date" }),
          formatISO(parseISO("2024-12-27"), { representation: "date" }),
          formatISO(parseISO("2024-12-28"), { representation: "date" }),
          formatISO(parseISO("2024-12-29"), { representation: "date" }),
          formatISO(parseISO("2024-12-30"), { representation: "date" }),
          formatISO(parseISO("2024-12-31"), { representation: "date" }),
          formatISO(parseISO("2025-01-01"), { representation: "date" }),
          formatISO(parseISO("2025-01-02"), { representation: "date" }),
          formatISO(parseISO("2025-01-03"), { representation: "date" }),
          formatISO(parseISO("2025-01-04"), { representation: "date" }),
          formatISO(parseISO("2025-01-05"), { representation: "date" }),

          formatISO(parseISO("2025-01-06"), { representation: "date" }),
          formatISO(parseISO("2025-01-08"), { representation: "date" }),
          formatISO(parseISO("2025-01-09"), { representation: "date" }),
          formatISO(parseISO("2025-01-10"), { representation: "date" }),
        ];

        const diaSemana = getDay(value);

        if (
          // (diaSemana === 3 && sede !== 1) ||
          diaSemana === 0 ||
          diaSemana === 6 ||
          (bloqueioCuiaba.includes(selected) && sede === 1) ||
          (bloqueioRoo.includes(selected) && sede === 2) ||
          (bloqueioTang.includes(selected) && sede === 3) ||
          (bloqueioSinop.includes(selected) && sede === 4) ||
          (bloqueioBarra.includes(selected) && sede === 5) ||
          selected <= new Date()
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  function disableWeekends(date) {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  const handleDate = async value => {
    const diaSemana = getDay(value);

    if (!diaSemana) {
      return false;
    }
    if (sede) {
      let dataBloqueio = new Date("2022-02-20");
      // let dataBloqueio = new Date("2022-12-02");
      if (handleBloqueio(value)) {
        setDate(value);

        if (diaSemana === 0 || diaSemana === 6) {
          setDate(null);
          return false;
        }
        const dataSelecionada = new Date(value);
        try {
          await api.get(`/check_data?date=${format(value, "yyyy/MM/dd")}&setor_destino=${setor_destino}&sede_id=${sede}`).then(({ data }) => {
            let horarios_ = horarios.map(item => {
              let obj = { horario: item.horario, disabled: false };
              data.forEach(({ horario, count }) => {
                if (horario === item.horario) {
                  obj.horario = horario;
                  obj.disabled = count > 0 ? true : false;
                }
              });
              if ((item.horario === "08:00" || item.horario === "09:00") && diaSemana === 2) {
                obj.disabled = true;
              }
              if ((item.horario === "08:00" || item.horario === "09:00") && dataSelecionada === dataBloqueio) {
                obj.disabled = true;
              }

              if ((item.horario === "15:00" || item.horario === "16:00" || item.horario === "17:00") && sede == "1") {
                obj.disabled = true;
              }
              //  const tarde = ["13:00", "14:00", "15:00", "13:30"];
              // if (dataSelecionada <= limite && sede !== 1 && tarde.includes(item.horario)) {
              //   obj.disabled = true;
              // }
              return obj;
            });
            setHorarios(horarios_);
          });
        } catch (error) {
          console.log(error);
          Alert("Erro", "Erro ao buscar horários, tente novamente", "error");
        }
      } else {
        Alert("Erro", "Data indisponivel, selecione outra data", "error");
      }
    }
  };

  const handleSubmit = async () => {
    const data = { name, email, phone, horario, setor_destino, cro, sede_id: sede, date, solicitacao };
    await formSchema
      .validate(data)
      .then(() => {
        try {
          setDisabled(true);
          api.post("/agendamento", data).then(() => {
            setName("");
            setEmail("");
            setPhone("");
            setHorario("");
            setSetorDestino("");
            setCRO("");
            setSede("");
            setDate(null);
            setSolicitacao("");
            Alert("Sucesso", "Agendamento realizado com sucesso!", "success");
            setDisabled(false);
          });
        } catch (err) {
          Alert("Erro", `${err.errors}`, "error");
          setDisabled(false);
        }
      })
      .catch(err => {
        Alert("Erro", `${err.errors}`, "error");
        setDisabled(false);
      });
  };

  const horariosRender = horarios.map((item, index) => (
    <HorarionButton
      key={index}
      className={horario === item.horario ? "active" : ""}
      disabled={item.disabled}
      onClick={
        item.disabled
          ? () => {}
          : () => {
              if (date) {
                setHorario(item.horario);
              } else {
                Alert("Erro", "Selecione a data primeiro", "error");
              }
            }
      }>
      {item.horario}
    </HorarionButton>
  ));

  return (
    <div>
      <Helmet>
        <title>Agendamento Online - {process.env.REACT_APP_TITLE}</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Agendamento Online - ${process.env.REACT_APP_TITLE}`} />
        <meta name="title" content={`Agendamento Online - ${process.env.REACT_APP_TITLE}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Main id="main">
        <Container>
          <Content>
            <Title title="Agendamento Online" />
            <ContentMain>
              <Aside>
                <MainServices />
              </Aside>
              <ContentBody>
                <ContentBox>
                  <AvisoBox>
                    <AvisoTitle>Aviso</AvisoTitle>
                    <AvisoContent>
                      Considerando a Decisão CRO-MT 018/2020, serão mantidos e priorizados os serviços remotos e, o atendimento presencial, ocorrerá somente em
                      casos excepcionais, mediante agendamento prévio e autorizado, enquanto perdurar a pandemia do Covid-19.
                    </AvisoContent>
                  </AvisoBox>
                  <Form>
                    <FieldContainer>
                      <Label>SEDE</Label>
                      <Select
                        placeholder="Selecione"
                        options={sedeOptions}
                        onChange={({ value }) => {
                          setName("");
                          setEmail("");
                          setPhone("");
                          setHorario("");
                          setSetorDestino("");
                          setCRO("");
                          setDate(null);
                          setSolicitacao("");
                          setSede(value);
                        }}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <Label>NOME COMPLETO</Label>
                      <InputText value={name} onChange={e => setName(e.target.value)} required={true} />
                    </FieldContainer>
                    <FieldContainer>
                      <Label>EMAIL</Label>
                      <InputText value={email} onChange={e => setEmail(e.target.value)} required={true} />
                    </FieldContainer>
                    <Collumn>
                      <CollumnContainer>
                        <FieldContainer>
                          <Label>TELEFONE</Label>
                          <InputText value={phone} onChange={e => setPhone(e.target.value)} required={true} />
                        </FieldContainer>
                      </CollumnContainer>
                      <CollumnContainer>
                        <FieldContainer>
                          <Label>Nº DO CRO-MT</Label>
                          <InputText value={cro} onChange={e => setCRO(e.target.value)} required={true} />
                        </FieldContainer>
                      </CollumnContainer>
                    </Collumn>
                    <FieldContainer>
                      <Label>SETOR DE DESTINO</Label>
                      <Select
                        placeholder="Selecione"
                        options={sede === 3 ? destino.filter(i => i.value === "Fiscalização") : destino}
                        onChange={({ value }) => setSetorDestino(value)}
                        required={true}
                      />
                    </FieldContainer>
                    <Collumn>
                      <CollumnContainer>
                        <FieldContainer>
                          <Label>DATA</Label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              shouldDisableDate={disableWeekends}
                              disableToolbar
                              format="dd/MM/yyyy"
                              margin="normal"
                              value={date}
                              onChange={handleDate}
                              required
                            />
                          </MuiPickersUtilsProvider>
                        </FieldContainer>
                      </CollumnContainer>
                      <CollumnContainer>
                        <FieldContainer>
                          <Label>HORÁRIO</Label>
                          <HorarioContainer>{horariosRender}</HorarioContainer>
                        </FieldContainer>
                      </CollumnContainer>
                    </Collumn>
                    <FieldContainer>
                      <Label>SOLICITAÇÃO</Label>
                      <TextArea rows="2" value={solicitacao} onChange={e => setSolicitacao(e.target.value)} required={true} />
                    </FieldContainer>
                    <EnviarContainer>
                      <Enviar disabled={disabled} onClick={handleSubmit}>
                        Enviar
                      </Enviar>
                    </EnviarContainer>
                  </Form>
                </ContentBox>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
      </Main>
      <Header />
    </div>
  );
}

export default Agendamento;
